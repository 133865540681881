var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            attrs: { size: "medium" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "detailWrapper" }, [
        _vm._m(0),
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                return _vm.downloadMobile($event)
              },
            },
          },
          [
            _c(
              "el-form",
              { attrs: { "label-width": "120px" } },
              [
                _c("el-form-item", { attrs: { label: "活动名称:" } }, [
                  _vm._v(" " + _vm._s(_vm.infoDetail.activityName) + " "),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "关联优惠券:" } },
                  _vm._l(_vm.infoDetail.couponRuleActivities, function (item) {
                    return _c("div", { key: item.couponRuleActivityId }, [
                      _vm._v(
                        " " +
                          _vm._s(item.couponName) +
                          " " +
                          _vm._s(item.publishAmount) +
                          "张 "
                      ),
                    ])
                  }),
                  0
                ),
                _c("el-form-item", { attrs: { label: "发放群组:" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("publishGroupFilter")(
                          _vm.infoDetail.publishGroup
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("el-form-item", { attrs: { label: "推送通知:" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("modeFilter")(_vm.infoDetail.couponPushMode)
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", [
          _vm._m(1),
          _c("div", { staticClass: "table-box" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("p", [
                _vm._v(
                  " 发放中非会员手机号，" +
                    _vm._s(
                      _vm.nonMemberMobileList && _vm.nonMemberMobileList.length
                    ) +
                    "个： "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.nonMemberMobileList &&
                        _vm.nonMemberMobileList.join(",")
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "tableWrapper bgFFF paddingB10" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "summary-method": _vm.getSummaries,
                      "show-summary": "",
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "header-align": "center",
                        type: "index",
                        label: _vm.$t("list.index"),
                        width: "70",
                      },
                    }),
                    _vm._l(_vm.tableCols, function (item) {
                      return _c("el-table-column", {
                        key: item.id,
                        attrs: {
                          align: "center",
                          "header-align": "center",
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          formatter: item.formatter,
                        },
                      })
                    }),
                  ],
                  2
                ),
                _c("div", { staticClass: "pagerWrapper" }, [
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.pageNum,
                          "page-size": _vm.pageSize,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.total,
                        },
                        on: { "current-change": _vm.handleCurrentChange },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 查看 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 详情 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }