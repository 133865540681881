<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button class="right" size="medium" @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <!--基本信息-->
      <div class="detailWrapper">
        <h2 class="title">
          <div class="title_icon"></div>
          <!-- 活动详情 -->
          查看
        </h2>
        <div @click="downloadMobile($event)">
          <!-- <el-row :gutter="20"> -->
          <!-- <el-col :span="12" :offset="6"> -->
          <!-- <InfoList :infoData="infoData1" :infoDetail="infoDetail" :infoSize="infoSize"></InfoList> -->
          <el-form label-width="120px">
            <el-form-item label="活动名称:">
              {{ infoDetail.activityName }}
            </el-form-item>
            <el-form-item label="关联优惠券:">
              <div
                v-for="item in infoDetail.couponRuleActivities"
                :key="item.couponRuleActivityId"
              >
                {{ item.couponName }} {{ item.publishAmount }}张
              </div>
            </el-form-item>
            <!-- <el-form-item label="用户标签:" v-if="infoDetail.couponPushMode == 0">
                {{ infoDetail.tags }}
              </el-form-item> -->
            <el-form-item label="发放群组:">
              {{ infoDetail.publishGroup | publishGroupFilter }}
            </el-form-item>
            <el-form-item label="推送通知:">
              {{ infoDetail.couponPushMode | modeFilter }}
            </el-form-item>
            <!-- <el-form-item label="发行量:">
                {{ infoDetail.publishAmount }}
              </el-form-item>
              <el-form-item label="发放人:">
                {{ infoDetail.creatorName }}
              </el-form-item>
              <el-form-item label="发放时间:">
                {{ infoDetail.createdTime }}
              </el-form-item> -->
          </el-form>
          <!-- </el-col> -->
          <!-- </el-row> -->
        </div>
        <div>
          <h2 class="title">
            <div class="title_icon"></div>
            <!-- 活动详情 -->
            详情
          </h2>
          <div class="table-box">
            <div class="title-box">
              <p>
                发放中非会员手机号，{{
                  nonMemberMobileList && nonMemberMobileList.length
                }}个：
              </p>
              <p>
                {{ nonMemberMobileList && nonMemberMobileList.join(",") }}
              </p>
            </div>
            <!--列表区域-->
            <div class="tableWrapper bgFFF paddingB10">
              <el-table
                v-loading="loading"
                :data="tableData"
                :summary-method="getSummaries"
                show-summary
                border
                style="width: 100%"
              >
                <el-table-column
                  align="center"
                  header-align="center"
                  type="index"
                  :label="$t('list.index')"
                  width="70"
                ></el-table-column>
                <el-table-column
                  align="center"
                  header-align="center"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  v-for="item in tableCols"
                  :key="item.id"
                  :formatter="item.formatter"
                />
              </el-table>
              <!--分页器-->
              <div class="pagerWrapper">
                <div class="block">
                  <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import InfoList from '../../components/infoList/infoList'
export default {
  name: "hello",
  filters: {
    couponFilter(val) {
      if (!val) return;
      let arr = [];
      val.forEach((item) => {
        arr.push(item.couponName);
      });
      return arr.join("、");
    },
    modeFilter(val) {
      if (!val) return;
      return val.replace("0", "APP推送").replace("1", "短信推送");
    },
    publishGroupFilter(val) {
      const arr = ["按用户标签", "按手机号"];
      return arr[val];
    },
  },
  data() {
    return {
      pageSize: 15,
      pageNum: 1,
      total: 0,
      loading: false,
      isLeadin: false,
      hidden: false,
      rsTypeList: {},
      infoSize: 11,
      infoDetail: {},
      tableData: [],
      tableCols: [
        {
          prop: "couponName",
          label: "优惠券",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "usedTimes",
          label: "使用时间",
        },
      ],
      nonMemberMobileList: [],
      totalUsedCount: 0
    };
  },
  computed: {
    infoData1() {
      return [
        {
          label: "活动名称",
          value: "activityName",
        },
        {
          label: "关联优惠券",
          value: "couponNames",
          formatter: (column, row) => {
            let couponNames = "<div></div>";
            let couponNamesArray = row.couponRuleActivities;
            couponNamesArray.map((item, index) => {
              couponNames +=
                "<p>" +
                item.couponName +
                " " +
                row.publishAmount +
                "张" +
                "</p>";
            });
            return couponNames;
          },
        },
        {
          label: "发放用户",
          value: "mobiles",
          formatter: (column, row) => {
            if (row.type == 0) {
              return row.mobiles;
            } else if (row.type == 1) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.isLeadin = true;
              return (
                '<button class="download_btn" style="' +
                "display: inline-block;\n" +
                "line-height: 1;\n" +
                "white-space: nowrap;\n" +
                "cursor: pointer;\n" +
                "background: #fff;\n" +
                "border: 1px solid #dcdfe6;\n" +
                "border-color: #dcdfe6;\n" +
                "color: #606266;\n" +
                "-webkit-appearance: none;\n" +
                "text-align: center;\n" +
                "box-sizing: border-box;\n" +
                "outline: none;\n" +
                "margin: 0;\n" +
                "transition: .1s;\n" +
                "font-weight: 500;\n" +
                "-moz-user-select: none;\n" +
                "-webkit-user-select: none;\n" +
                "-ms-user-select: none;\n" +
                "padding: 6px 8px;\n" +
                "font-size: 12px;\n" +
                "border-radius: 4px;" +
                "color: #fff;\n" +
                "background-color: #0f6eff;\n" +
                "border-color: #0f6eff;" +
                '">下载明细</button>'
              );
            }
          },
        },
        {
          label: "优惠券推送通知",
          value: "couponPushMode",
          // formatter: function (column, row) {
          //   let showMode = ''
          //   let newArray = []
          //   if (row.couponPushMode && row.couponPushMode.length > 1) {
          //     newArray = row.couponPushMode.split(',')
          //   } else {
          //     newArray = row.couponPushMode.split('')
          //   }
          //   newArray.map((item) => {
          //     if (item == 0) {
          //       showMode += 'APP推送 '
          //     } else if (item == 1) {
          //       showMode += '短信推送 '
          //     } else if (item == 2) {
          //       showMode += '公众号推送 '
          //     }
          //   })
          //   return showMode
          // }
        },
        {
          label: "发行量",
          value: "publishAmount",
        },
        {
          label: "发放人",
          value: "creatorName",
        },
        {
          label: "发放时间",
          value: "createdTime",
        },
      ];
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.handleGetList();
    },
    downloadMobile(e) {
      if (e.path[0].className == "download_btn") {
        window.open(
          '/gplus/couponDistribute/excelExport?param={"data":{"id":"' +
            this.infoDetail.couponDistributeId +
            '"},"cityId":"40","k":"' +
            sessionStorage.token +
            '"}',
          "_blank"
        );
      } else {
        return false;
      }
    },
    getDetailInfo() {
      const opt = {
        method: "get",
        url:
          "/acb/2.0/distributeActivity/getById?distributeActivitId=" +
          this.$route.query.distributeActivityId,
        data: {},
        success: (res) => {
          this.infoDetail = res.value;
        },
      };
      this.$request(opt);
    },
    handleGetList() {
      this.tableData = [];
      this.loading = true;
      const tempData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        activityId: this.$route.query.distributeActivityId,
      };
      const opt = {
        method: "get",
        url: "/acb/2.0/distributeActivity/findDistributeDetailById",
        data: tempData,
        success: (res) => {
          this.loading = false;
          this.nonMemberMobileList = res.value.nonMemberMobileList || [];
          this.totalUsedCount = res.value.totalUsedCount || 0;
          this.tableData = (res.value.detailVoPageResult && res.value.detailVoPageResult.list) || [];
          this.total = (res.value.detailVoPageResult && res.value.detailVoPageResult.total * 1) || 0;
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    getSummaries(param) {
      return ["共计", "", this.total, this.totalUsedCount];
    },
  },
  components: {
    // InfoList
  },
  created() {
    this.getDetailInfo();
    this.handleGetList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
  overflow: hidden;

  .detail_pic {
    text-align: center;

    .pic_title {
      font-size: 12px;
      color: #475669;
      height: 20px;
      line-height: 20px;

      .icon-circle {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #0099FA;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .table-box {
    width: 100%;

    .title-box {
      width: 100%;
      padding-left: 50px;
      box-sizing: border-box;
      font-size: 14px;
      p {
        width: 100%;
        margin-bottom: 8px;
        word-wrap: break-word;
        word-break: normal;
        &:last-child {
          line-height: 1.3;
        }
      }
    }
  }
}
</style>
